import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import AppRoute from '../utils/AppRoute';
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  return (
    <AppRoute {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect to="/login" />
      )}
    </AppRoute>
  );
}
