export default {
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_CJFbpii0i",
    APP_CLIENT_ID: "41f1p5k8nj9krcqanllu57rbd0",
    IDENTITY_POOL_ID: "us-east-2:37ec0052-049d-491d-bba1-4f4088725b0f"
  },
  oauth: {
    "domain": "https://larkinai.auth.us-east-2.amazoncognito.com/"
  }
};
