import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Switch, useHistory } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import ScrollToTop from 'react-router-scroll-top';
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutSignin from './layouts/LayoutSignin';
import LayoutApp from './layouts/LayoutApp';

// Landing Page Views
import Home from './views/Home';
import About from './views/About';
import Faq from './views/Faq';
import Pricing from './views/Pricing';
import Login from './views/Login';
import Signup from './views/Signup';
import NotFound from './views/NotFound';

// App Views
import Dashboard from './views/Dashboard';
import Settings from './views/Settings';
import Billing from './views/Billing';

// Routes
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};


function App() {

  const history = useHistory();

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  const childRef = useRef();
  let location = useLocation();

  // Session config

  useEffect(() => {
    // const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // trackPage(page);
    // onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    async function onLoad() {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      }
      catch(e) {
        if (e !== 'No current user') {
          alert(e);
        }
      }
        setIsAuthenticating(false);
    }

    async function handleLogout() {
      await Auth.signOut();

      userHasAuthenticated(false);

      history.push("/login")
    }

  return (
      <ScrollToTop>
      <ScrollReveal
      ref={childRef}
      children={() => (
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}  >
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/about" component={About} layout={LayoutDefault} />
          <AppRoute exact path="/faq" component={Faq} layout={LayoutDefault} />
          <AppRoute exact path="/pricing" component={Pricing} layout={LayoutDefault} />

          <AuthenticatedRoute exact path="/dashboard" component={Dashboard} layout={LayoutApp} />
          <AuthenticatedRoute path="/settings" component={Settings} layout={LayoutApp} />
          <AuthenticatedRoute exact path="/billing" component={Billing} layout={LayoutApp} />

          <UnauthenticatedRoute exact path ="/login" component={Login} layout={LayoutSignin} />
          <UnauthenticatedRoute exact path ="/signup" component={Signup} layout={LayoutSignin} />

          <AppRoute component={NotFound} layout={LayoutDefault} />
        </Switch>
        </AppContext.Provider>
      )} />
      </ScrollToTop>
  );
}

export default App;
