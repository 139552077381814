import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { Link } from 'react-router-dom';
import Input from '../components/elements/Input';
import Button from '../components/elements/Button';
import Checkbox from '../components/elements/Checkbox';
import {
  FormGroup,
  FormControl,
  FormLabel
} from "react-bootstrap";
import { useAppContext } from '../libs/contextLib';
import { useFormFields } from '../libs/hooksLib';
import { Auth } from "aws-amplify";
import { onError } from '../libs/errorLib';

export default function Signup() {

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationcode] = useState("");

  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

    const sectionHeader = {
      title: 'Sign up for Larkin today',
      paragraph: 'Please use your email address as your username and choose a password that has over 8 characters, at least one special character, and a mix of upper and lower case characters.'
    }

    function validateForm() {
        return (
          fields.email.length > 0 &&
          fields.password.length > 0 &&
          fields.password === fields.confirmPassword
        );
      }

      function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
      }

      async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
          const newUser = await Auth.signUp({
            username: email,
            password: password,
          });
          setIsLoading(false);
          setNewUser(newUser);
          // console.log({ newUser });
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
      }

      async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
          await Auth.confirmSignUp(email, confirmationCode);
          await Auth.signIn(email, password);
          alert("Confirmed");
          userHasAuthenticated(true);
          history.push("/dashboard");
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
      }

      function renderConfirmationForm() {
      return (
        <React.Fragment>
          <GenericSection>
          <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
          <div className="tiles-wrap">
            <div className="tiles-item">
            <div className="tiles-item-inner">
              <form onSubmit={handleConfirmationSubmit}>
              <div className="mb-12">
                <FormGroup className="loginform" controlId="confirmationCode">
                  <Input
                    autoFocus
                    className="form-input"
                    type="tel"
                    onChange={e => setConfirmationcode(e.target.value)}
                    placeholder="Confirmation Code (via Email)"
                    value={confirmationCode}
                  />
                </FormGroup>
              </div>
              <div className="mt-24 mb-32">
                  <Button color="primary" type="submit" wide>
                    Submit confirmation code
                  </Button>
                </div>
              </form>
            </div>
            </div>
          </div>
          </GenericSection>
        </React.Fragment>
      );
    }

  function renderForm() {
    return (
      <React.Fragment>
        <GenericSection>
        <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
        <div className="tiles-wrap">
          <div className="tiles-item">

          <div className="tiles-item-inner">
            <form onSubmit={handleSubmit}>
            <div className="mb-12">
              <FormGroup className="loginform" controlId="email">
                <Input
                  autoFocus
                  className="form-input"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  placeholder="Email"
                  labelHidden
                  required
                />
              </FormGroup>
            </div>
            <div className="mb-12">
            <FormGroup className="loginform" controlId="password">
              <Input
                autoFocus
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                label="Password"
                placeholder="Password"
                labelHidden
                required
              />
            </FormGroup>
            </div>
              <div className="mt-24 mb-32">
                <Button color="primary" type="submit" wide>
                  Sign up
                </Button>
              </div>
            </form>
            <div className="signin-bottom has-top-divider">
              <div className="pt-32 text-xs center-content text-color-low">
                Already have an account? <Link to="/login" className="func-link">Log in here</Link>
              </div>
            </div>
          </div>


          </div>
        </div>
        </GenericSection>
      </React.Fragment>
    );
  }

  return (
  <div className="Signup">
    {newUser === null ? renderForm() : renderConfirmationForm()}
  </div>
  );

}
