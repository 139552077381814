import React, { useState } from 'react';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { Link } from 'react-router-dom';
import Input from '../components/elements/Input';
import Button from '../components/elements/Button';
import Checkbox from '../components/elements/Checkbox';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import Amplify, { Auth, Hub } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { onError } from '../libs/errorLib';

const sectionHeader = {
  title: 'Log in to start writing'
}

export default function Login() {

  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }


  async function handleSubmit(event) {
    event.preventDefault();

    try {
      await Auth.signIn(email, password);
      alert("Logged in");
      userHasAuthenticated(true);
      console.log({ email });
      history.push("/dashboard");
    } catch (e) {
      alert(e.message);
    }
  }

return (
  <React.Fragment>
    <GenericSection>
      <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
      <div className="tiles-wrap">
        <div className="tiles-item">
          <div className="tiles-item-inner">
            <form onSubmit={handleSubmit}>
            <div className="mb-12">
              <FormGroup className="loginform" controlId="email">
                <Input
                  autoFocus
                  className="form-input"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  placeholder="Email"
                  labelHidden
                  required
                />
              </FormGroup>
            </div>
            <div className="mb-12">
            <FormGroup className="loginform" controlId="password">
              <Input
                autoFocus
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                label="Password"
                placeholder="Password"
                labelHidden
                required
              />
            </FormGroup>
            </div>
              <div className="mt-24 mb-32">
                <Button color="primary" type="submit" wide>
                  Login
                </Button>
              </div>
              </form>
              <div className="signin-footer mb-32">
              <Checkbox>Remember me</Checkbox>
              <Link to="/recover-password/" className="func-link text-xs">Forgot password?</Link>
            </div>
            <div className="signin-bottom has-top-divider">
              <div className="pt-32 text-xs center-content text-color-low">
                Don't have an account yet? <Link to="/signup/" className="func-link">Sign up here</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GenericSection>
  </React.Fragment>

);

}
