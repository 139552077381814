import React from 'react';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';

class About extends React.Component {
  render() {

    const sectionHeader = {
      title: 'Meet Larkin'
    }

    return (
      <React.Fragment>
        <GenericSection>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h2>Larkin is a content marketing tool powered by AI.</h2>
          <p>Business owners know that engaging content is the <strong>best marketing investment they can make</strong>, since it generates evergreen leads. The problem? Working with freelancers is slow and expensive. Even when you get drafts back, they may not be what you wanted.</p>
          <p><strong>Larkin generates blog post drafts in seconds</strong>, based on a few sentences of input, so you can level up your content quickly and inexpensively.</p>
          <p>To get early access, sign up with your email below.</p>
        </GenericSection>
        <Cta topDivider split />
      </React.Fragment>
    );
  }
}

export default About;
