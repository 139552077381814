import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Sign up for a free trial
              </h3>
          </div>
          <div className="cta-action">
            <a name="signupsection"></a>
              <form name="contact" method="post">
                <input type="hidden" name="form-name" value="contact" />
                <Input id="newsletter" type="email" name="email" label="Subscribe" labelHidden placeholder="Your email address" formGroup="mobile">
                  <Button color="primary">Submit</Button>
                </Input>
                {/*
                <Input id="newsletter" name="form-name" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your email address">
                  <label>
                    <input type="submit" />
                    <svg style={{right:'15px'}} width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                    </svg>
                  </label>
                </Input>*/}
              </form>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
