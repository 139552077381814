import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import config from '../config';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';


export default function Billing() {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const sectionHeader = {
      title: 'Billing'
    }

    return (
      <React.Fragment>
        <GenericSection>
          <SectionHeader data={sectionHeader} className="center-content" />

        </GenericSection>
      </React.Fragment>
    );
  }
