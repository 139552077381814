import React, { useState, useEffect } from 'react';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';

class Dashboard extends React.Component {
  render() {

    const sectionHeader = {
      title: 'Dashboard'
    }

    return (
      <React.Fragment>
        <GenericSection>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h5>Launching soon.</h5>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Dashboard;
