import React from 'react';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';

class About extends React.Component {
  render() {

    const sectionHeader = {
      title: 'Page Not Found'
    }

    return (
      <React.Fragment>
        <GenericSection>
          <SectionHeader data={sectionHeader} className="center-content" />
          <p>Sorry, we couldn't find your page!</p>
          <p>Try logging in above if this is a bookmarked link.</p>
        </GenericSection>
        <Cta topDivider split />
      </React.Fragment>
    );
  }
}

export default About;
