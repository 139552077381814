import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import config from '../config';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Button from '../components/elements/Button';
import { Auth } from "aws-amplify";

export default function Settings() {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [isAuthenticated, userHasAuthenticated] = useState(true);

    function billUser(details) {
      return API.post("notes", "/billing", {
        body: details
      });
    }

    const sectionHeader = {
      title: 'Settings'
    }

    async function handleLogout() {
      await Auth.signOut();
      userHasAuthenticated(false);
      history.push("/")
    }

    return (
      <React.Fragment>
        <GenericSection>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h5>Launching soon.</h5>
          <Button onClick={handleLogout}>Logout</Button>
        </GenericSection>
      </React.Fragment>
    );
  }
