import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';
import Button from '../components/elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Pricing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Pricing that fits your needs',
    paragraph: 'Switching from freelancers to Larkin can save your business hundreds of dollars a month, while also increasing customer engagement and retention.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-no-bg">
                  <h3>Light</h3>
                  <p className="text-sm mb-0">
                    &#10003; Generate 1 article monthly <br />
                    &#10003; Cut your freelance costs <br />
                    &#10003; Keep your articles forever <br />
                    &#10003; 7-day free trial <br />
                  </p>
                </div>
                <div className="testimonial-item-footer text-s mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">$50 monthly</span>
                </div>
                <div className="mt-24 mb-8">
                  <Button tag="a" wide href="#">Sign up for Light</Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-no-bg">
                  <h3>Standard</h3>
                  <p className="text-sm mb-0">
                    &#10003; Generate 5 articles monthly <br />
                    &#10003; Free article review <br />
                    &#10003; Keep your articles forever <br />
                    &#10003; 14-day free trial <br />
                  </p>
                </div>
                <div className="testimonial-item-footer text-s mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">$100 monthly</span>
                </div>
                <div className="mt-24 mb-8">
                  <Button tag="a" color="primary" wide href="#">Sign up for Standard</Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-no-bg">
                  <h3>Premium</h3>
                  <p className="text-sm mb-0">
                    &#10003; Unlimited articles monthly <br />
                    &#10003; Free article review <br />
                    &#10003; Free SEO scoring <br />
                    &#10003; Keep your articles forever <br />
                  </p>
                </div>
                <div className="testimonial-item-footer text-s mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Custom quote</span>
                </div>
                <div className="mt-24 mb-8">
                  <Button tag="a" wide href="#">Get in touch</Button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    <Cta topDivider split />
    </section>

  );
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
