import React from 'react';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';

class Faq extends React.Component {
  render() {

    const sectionHeader = {
      title: 'Frequently asked questions'
    }

    return (
      <React.Fragment>
        <GenericSection>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h2>How does Larkin work?</h2>
          <p><strong>Larkin generates blog post drafts in seconds</strong>, based on a few sentences of input, so you can level up your content quickly and inexpensively. We've trained the AI model that powers Larkin on tens of thousands of high-performing blog posts.</p>
          <p>To get early access, sign up with your email below.</p>
          <h2>Should I review Larkin's drafts before publishing?</h2>
          <p><strong>Yes, we highly recommend reading through and editing Larkin's drafts at least once</strong>, as you would with a freelancer's work, to make sure it accurately reflects your business' details and values.</p>
          <p>Think of Larkin's drafts as a way to get a polished draft, quickly, that just needs one more look before publishing.</p>
          <h2>Do you offer non-standard pricing plans?</h2>
          <p>We've found that our current pricing plans meet the needs of most users. If you'd like a custom quote, however, you can reach out to <a href="mailto:hello@larkinai.com">hello@larkinai.com</a> and we would be happy to chat.</p>
        </GenericSection>
        <Cta topDivider split />
      </React.Fragment>
    );
  }
}

export default Faq;
